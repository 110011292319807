.web_page {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
}

.web_content {
  padding: 30px 0;
}

.react-typewriter-text-wrap {
  margin: 2% auto;
}

@media only screen and (max-width: 768px) {
  .web_page {
   flex-wrap: wrap;
  }
}