@import '../../assets/styles/variables.scss';

//// Tab Menu 2////

.tab_menu_block_2 {
  z-index: 888;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: none;
  padding: 3px 0;
  .tab_menu_list {
    padding: 0;
    background: var(--background_main);
    box-shadow: 0 0 10px var(--neutral_main) !important;
    border-radius: 0 !important;
  }
  .Joy-selected {
    background-color: var(--primary_light) !important;
    color: var(--primary_main) !important;
    span {
      font-size: 22px;
      color: var(--primary_main) !important;
      fill: var(--primary_main) !important;
    }
  }
  .tab_menu_item_block_2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .tab_menu_item_2 {
    font-size: 8px;
    background: var(--background_main);
    color: var(--text_main);
    border-radius: 0 !important;
    margin: 0;
    span {
      font-size: 22px;
      color: var(--text_main);
      fill: var(--text_main);
      svg {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .tab_menu_block_2 {
    display: flex;
  }
}
