@import './variables';

.invalid_message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.page_loger .MuiDataGrid-cellContent {
  white-space: pre-wrap !important;
}

.month_year_picker {
  div {
    input {
      padding: 7.5px 7.5px 7.5px !important;
    }

    svg {
      fill: #3f3f3f;
      font-size: 1.1rem;
    }
  }
}

.text_area_input {
  resize: vertical;
}

.input[name="voucher_preview_link"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input[name="password"] {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.MuiPickersPopper-root {
  z-index: 10000 !important;
}

.MuiDataGrid-columnSeparator {
  &:hover {
    .MuiDataGrid-iconSeparator {
      color: #fff !important;
    }
  }

  .MuiDataGrid-iconSeparator {
    color: #393e42 !important;
  }
}

.Toastify__toast-container {
  z-index: 100000 !important;
}

.admin_tables_block.page_loger {
  height: 74%;
}

.fade,
.modal-backdrop {
  z-index: 10000 !important;
}

input[type='date'],
input[type='time'] {
  height: 38px;
}

.ag-icon-menu {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='month']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  //display: none;
  background-image: url('../images/calendar.svg');
  background-color: #f5f5f5;
  -webkit-background-image: url('../images/calendar.svg'); /* Safari */
  -o-background-image: url('../images/calendar.svg'); /* Opera */
  -moz-background-image: url('../images/calendar.svg') !important; /* Firefox */
}

input[type='date'],
input[type='month'] {
  cursor: pointer;
  background-position: right;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: url('../images/calendar.svg');
  -webkit-background-image: url('../images/calendar.svg') !important; /* Safari */
  //-moz-background-image: none !important; /* Firefox */
  -o-background-image: url('../images/calendar.svg'); /* Opera */
}

//.info_svg {
//  fill: var(--primary_main) !important;
//  margin-right: 4px;
//}
.info_div {
  display: flex;
  align-items: flex-start;
  padding-left: 3px;
}

.MuiDataGrid-row,
.actions {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiDataGrid-row {
  color: var(--text_main);

  &.Mui-selected {
    background-color: var(--neutral_light) !important;
  }
}

.actions {
  position: sticky !important;
  border-left: 1px solid rgba(224, 224, 224, 1);
}

.MuiDataGrid-pinnedColumns {
  right: -14 !important;
  background: var(--background_main) !important;

  div {
    background: var(--background_main) !important;
  }
}

.required_message {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.MuiDataGrid-pinnedColumnHeaders {
  padding: 0 14px 0 0 !important;
}

.password_input {
  padding: 0.375rem 0.75rem !important;
}

.admin_banner {
  display: flex;
  height: 100%;
}

.dashboard_ico {
  margin-right: 5px;
}

.mobile_action_block {
  button {
    margin-left: 10px;

    svg {
      font-size: 20px;
    }
  }
}

.form-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_dashboard_bar {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(224, 224, 224, 1);
  box-shadow: 0 0 10px rgba(224, 224, 224, 1);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.pass_visible1 {
  position: absolute;
  top: 49.1%;
  right: 42.5%;
}

.pass_visible {
  position: absolute;
  top: 35%;
  right: 4.5%;
}

.pass_visible_hosting {
  position: absolute;
  top: 35%;
  right: 36.5%;
}

.pass_visible_websites {
  position: absolute;
  top: 64%;
  right: 4.5%;
}

.admin_tables_block {
  width: 100%;
  //height: 100%;
  height: 82.8%;
  background: var(--background_main);
}

.paginate_input {
  margin: 0 10px;
  width: 40px;
  height: 20px;
}

.display_none {
  visibility: hidden;
  position: absolute;
}

.paginate_arrows_block {
  margin: 0 10px 0 10px;
}

.flex-row {
  display: flex;
}

li {
  list-style-type: none;
}

.dashboard-div {
  display: flex;
  justify-content: center;
}

.dashboard-empty-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.selected_side_bar_link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #00000017;
  font-size: 17px;
  padding-left: 20px;
  cursor: pointer;
}

.selected_side_bar_menu_link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #00000017;
  font-size: 17px;
  padding-left: 30px;
  cursor: pointer;
}

.selected_side_bar_menu_link.active {
  display: flex;
  align-items: center;
  background-color: var(--primary_light);
  color: var(--primary_dark);
  text-decoration: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #00000017;
}

.selected_side_bar_link.active {
  display: flex;
  align-items: center;
  background-color: var(--primary_light);
  color: var(--primary_dark);
  text-decoration: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #00000017;
}

.side_bar_link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);

  text-decoration: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #00000017;
}

.MuiDataGrid-actionsCell {
  button {
    svg {
      font-size: 16px;
      color: var(--primary_dark);
      fill: var(--primary_dark);
    }
  }
}

.MuiDataGrid-menuIcon {
  .MuiButtonBase-root {
    display: none !important;

    svg {
      font-size: 20px !important;
    }
  }
}

// .MuiButtonBase-root {
//   display: flex !important;
//   padding: 8px 16px !important;
// }
.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: var(--primary_dark) !important;
}

.css-imf0nl-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -14px !important;
}

.css-1miuj5f-MuiDataGrid-panelWrapper {
  color: var(--primary_dark) !important;
}

div[class='tooltip'] {
  position: absolute;
  inset: 0 auto auto 0;
  margin: 0;
  transform: translate(830px, 145px);
}

.MuiDataGrid-panel
.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel
.MuiPopperUnstyled-root {
  position: absolute;
  inset: 0 auto auto 0;
  margin: 0;
  transform: translate(830px, 145px);
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  float: right !important;
}

.MuiButton-root {
  color: var(--primary_dark) !important;
}

.add_new_div {
  display: flex;
  justify-content: flex-end;

  .addNewButton {
    padding: 7px;
    display: flex;
    background-color: var(--primary_main);
    color: white;
    font-weight: bold;
    border: 1px solid var(--primary_main);
    cursor: pointer;
  }
}

.pageTitle {
  font-size: 1rem;
  margin-left: 10px;
  font-weight: 600;
  color: var(--primary_dark);
  overflow: hidden;
  width: 80%;
  text-overflow: ellipsis;
}

.disabled {
  fill: #5e5e5e !important;
}

.disabled_action {
  opacity: 0.3;
  cursor: default !important;
}

.icon_block_mobile_view {
  display: flex;
  justify-content: flex-end;

  .search_block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    svg {
      font-size: 28px;
      fill: rgb(32 48 52 / 76%);
      margin-left: 5px;
      height: 100%;
    }

    .form-control {
      padding: 0.3rem 0.7rem;
      border: none;
      border-bottom: 1px solid #ced4da;

      &:focus {
        box-shadow: none;
      }
    }

    .btn {
      border: none;
      border-bottom: 1px solid #ced4da;
      padding: 0.3rem;

      &:focus-visible,
      &:active,
      &:hover {
        box-shadow: none;
        background: none;
      }
    }
  }
}

.mobile_add_block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.deleteConfirmText {
  font-size: 17px;
}

.success_toast {
  background-color: #e2e7e9;
  padding: 2px 5%;
  color: #2f662e;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 16px;
}

.btn-primary {
  color: #fff !important;
  background-color: #14213D !important;
  border-color: #e5e5e5a8 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #FCA311 !important;
  border-color: #e5e5e5a8 !important;
}

.btn-secondary {
  color: var(--neutral_dark) !important;
  background-color: var(--neutral_main) !important;
  border-color: var(--neutral_main) !important;
}

.btn-secondary:hover {
  color: white;
  background-color: var(--primary_main);
  border-color: var(--primary_main);
}

.modal_title {
  margin-bottom: 0;
  line-height: 1.5;
  color: white !important;
  font-weight: bold;
}

.modal_header {
  padding: 9px 15px;
}

.modal-header {
  background: #14213D;
  color: white !important;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: white;
  font-weight: bold;
}

.modal-header .btn-close {
  opacity: 1;
  background-image: url('../images/CloseButton.png');
}

.previewButtons > input[class="btn-primary"] {
  border: 1px solid var(--primary_main) !important;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  position: relative;
  background-image: none;
}

.was-validated .form-control[type='date']:invalid {
  padding-right: 5px;
}

.was-validated .form-control[type='month']:invalid {
  padding-right: 3px;
}

.was-validated .form-control[type='month']:valid {
  padding-right: 3px;
}

.was-validated .form-control[type='date']:valid {
  padding-right: 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: hsl(0, 0%, 95%);
  opacity: 1;
  color: grey;
}

//.react-select__option--is-disabled {
//  cursor: not-allowed !important;
//}

.toolBar {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    font-size: 22px;
    //color: var(--primary_dark);
  }

  span {
    //color: var(--primary_dark);
    font-weight: 600;
  }
}

.toolBar.disabled.action_block_item, .toolBar.disabled.action_block_item:hover {
  background-color: transparent;
  cursor: wait;
  user-select: none;
}

.fetch_action_div {
  margin: 0 5px 0 15px;
  padding: 0;
}

.dropdown-item:hover {
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #212529;
  background-color: white;
}

.copy_button {
  background-color: transparent;
  border: none;
}

.MuiDataGrid-cell--withRenderer {
  right: 0 !important;
  //background-color: white !important;
}

.logs {
  overflow: hidden;
  white-space: pre-wrap !important;
}

#preview {
  width: 300px;
}

.imageDiv {
  border: 1px solid rgb(206, 212, 218);
  display: flex;
  justify-content: space-around;
}

.form_input {
  background-image: none !important;
  padding-right: 10px !important;
}

.fake_input {
  height: 37px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 1% 3%;
}

.emailHidden {
  position: absolute !important;
  right: 0 !important;
  width: 145px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.hostingHidden {
  position: absolute !important;
  right: 0 !important;
  width: 185px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.websitesHidden {
  position: absolute !important;
  right: 0 !important;
  width: 155px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.paymentHidden {
  position: absolute !important;
  right: 0 !important;
  width: 155px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.messagesHidden {
  position: absolute !important;
  right: 0 !important;
  width: 155px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.directoriesHidden {
  position: absolute !important;
  right: 0 !important;
  width: 150px !important;
  background-color: white !important;
  z-index: 222 !important;
  top: 11% !important;
  color: white !important;
}

.online {
  display: none;
}

.dropdown_button {
  // margin-right: 10px;
}

.link-span-in-table {
  cursor: pointer;
  color: #0d6efd;
  overflow: hidden;
  text-overflow: ellipsis;

  a,
  span {
    color: #0d6efd;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


@media (max-width: 1320px) {
  .month_year_picker {
    div {
      input {
        font-size: 13px;
      }
    }
  }
}


@media (max-width: 1023px) {
  .mobile_add_block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .toolBar {
      padding-top: 0;
    }
  }

  .mobile_see_more {
    color: var(--primary_dark);
    font-weight: 600;

    svg {
      font-size: 22px;
      margin-right: 5px;
    }
  }
}

@media (max-width: 768px) {
  .selected_side_bar_link.active {
    height: 63px;
  }
}

@media (max-width: 520px) {
  .hostingHidden,
  .emailHidden,
  .paymentHidden,
  .websiteHidden {
    top: 15% !important;
  }
  .pageTitle {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .selected_side_bar_link.active {
    height: 50px;
  }
}

@media (max-width: 375px) {
  .online {
    font-size: 18px !important;
    display: inline-block;
    margin-left: 10px;
  }
  //.status_icon {
  //  display: none;
  //}
}

.userName {
  font-weight: bold;
  color: var(--primary_dark);
}

.grid_item {
  margin: 10px 0 !important;
}

.completed {
  font-size: 20px;
  color: green;
  margin-left: 10%;
}

.completed-profile {
  font-size: 20px;
  color: green;
  margin-left: 0;
}

.incompleted {
  font-size: 20px;
  color: #5e5e5e;
  margin-left: 10%;
}

.incompleted-profile {
  font-size: 20px;
  color: #5e5e5e;
  margin-left: 3%;
}

.profile-container {
  display: flex;
  justify-content: space-between;
}

.profile-edit-container {
  margin: 4% 0 0;
  //box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnHeader,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 3px !important;
}

.css-13ylpjl-MuiDataGrid-root .MuiDataGrid-columnHeader,
.css-13ylpjl-MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 3px !important;
}

.MuiTablePagination-displayedRows {
  display: block !important;
}

#preview {
  width: 250px;
}

.max_div {
  display: flex;
  justify-content: space-between;
}

.max_style {
  color: gray !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}

.max_style_order {
  color: gray !important;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}

.max_style_prev {
  color: gray !important;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}

.max_style_description {
  color: gray !important;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}

div[role='presentation'] {
  z-index: 10001 !important;
}

.imageDiv {
  width: 100%;
  height: 165px;
  border: 1px solid rgb(206, 212, 218);
  display: flex;
  justify-content: space-around;
}

.form_input {
  background-image: none !important;
  padding-right: 10px !important;
}

.fake_input {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 1%;
  padding-left: 3%;
}

.iconLogin {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 24%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon_login {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  box-sizing: border-box;
  top: 31%;
  right: 3px;
  transform: translateY(-50%);
  z-index: 999;
}

.iconActivate {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 42%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon_activate {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 42%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.iconPassword {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 48%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon-Password {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 63%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon_password {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 63%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon-password {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 48%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 65%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon_pass {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 48%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon-copy-pass {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 65%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon_copy_pass {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 48%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 999;
}

.iconRequired {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 999;
}

.icon-my-profile {
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px;
  position: absolute;
  box-sizing: border-box;
  top: 76%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 999;
}

.input {
  box-sizing: border-box;
}

.form-block {
  border: 1px solid #ced4da;
  padding: 15px 20px;
  height: auto;
  position: relative;
  background: var(--background_main);
}

.modal-body {
  padding: 0;
  color: var(--text_main);
}

.previewButtonsID {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  .btn {
    margin-left: 15px;
    max-width: 100px;
    text-transform: capitalize;
  }

  .btn-secondary {
    margin-left: 15px;
  }

  .dropdown-menu.show {
    transform: translate(-15px, 40px) !important;
    display: flex;
    justify-content: center;
  }

  .dropdown {
    .btn {
      margin-right: 0;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;

      &:hover {
        background-color: var(--primary_main);
        color: #fff;
      }
    }
  }
}

.previewButtons {
  display: flex;
  justify-content: flex-end;
  //padding-top: 5px;

  .btn {
    margin-left: 15px;
    max-width: 100px;
    text-transform: capitalize;
  }

  .btn-secondary {
    //margin-right: 10px;
  }

  .dropdown-menu.show {
    transform: translate(-15px, 40px) !important;
    display: flex;
    justify-content: center;
  }

  .dropdown {
    .btn {
      margin-right: 0;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;

      &:hover {
        background-color: var(--primary_main);
        color: #fff;
      }
    }
  }
}

.web_table {
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-pinnedColumnHeaders {
    font-size: 13px !important;
    background-color: var(--primary_light) !important;
  }
}

.web_table_nested {
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-pinnedColumnHeaders {
    font-size: 13px !important;
    background-color: var(--neutral_main) !important;
  }

  .MuiDataGrid-virtualScroller {
    background-color: var(--neutral_light) !important;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.was-validated .form-check-input:valid ~ .form-check-label {
  color: rgba(0, 0, 0, 0.87);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.form-check-input:checked {
  background-color: var(--primary_main);
  border-color: var(--primary_main);
}

.materials_item::placeholder {
  color: #212529;
}

input::placeholder {
  color: #b5b3b3 !important;
}

.pageNotFound {
  width: 50%;
  margin: auto;

  h1 {
    font-size: 200px;
    font-weight: bold;
    color: var(--primary_main);
    text-align: center;
  }

  h5 {
    font-size: 50px;
    color: var(--primary_main);
    text-align: center;
  }

  h6 {
    font-size: 20px;
    color: var(--primary_main);
    text-align: center;
    font-weight: bold;
  }
}

.MuiDataGrid-panel {
  //margin-top: -4% !important;
}

.MuiDataGrid-panel {
  position: absolute !important;
  inset: 0 auto auto 0 !important;
  margin: 0 !important;
  transform: translate(830px, 145px) !important;
}

.MuiTooltip-popperInteractive {
  //margin-top: -2% !important;
  .MuiTooltip-tooltip {
    background-color: #e2e8f0 !important;
    color: black !important;
    font-weight: bold;
    max-width: 370px !important;

    svg {
      color: black !important;
      font-size: 20px;
    }
  }
}

.loggerTooltip {
  transform-origin: center top;
  //margin-top: -5% !important;
}

textarea {
  resize: none;
}

.gridContainerStyle {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  //margin-top: 12px;
}

.categoryCardStyle {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 7px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  user-select: none;

  input[type="checkbox"] {
    margin-top: 0.20em;
    margin-right: 8px;
  }
}

.facilityCardStyle {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 7px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  user-select: none;

  input[type="checkbox"] {
    margin-top: 0.20em;
    margin-right: 8px;
  }
}


//Media styles
@media only screen and (max-width: 1023px) {
  .profile-container {
    display: flex;
    justify-content: space-between;
  }

  .about-profile {
    width: 100%;
    color: var(--primary_dark);
    margin: 15px 0 0 0;
    display: flex;
    justify-content: space-around;
    box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  }

  .user-avatar {
    width: 250px;
    height: 250px;
  }
  .user-info {
    padding: 50px 0 0 0;
  }
  .profile-h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 2.2;
  }

  .profile-edit-icon {
    margin: 50px 0 0 0;
  }
  .profile-edit-container {
    margin: 4% 0 0;
  }
}

@media only screen and (max-width: 1023px) {
  .admin_tables_title {
    min-height: 48px;
    height: auto;
    padding: 10px 1% 10px 0;
  }

  .mobile_add_block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .toolBar {
      padding-top: 0;
    }
  }

  .mobile_add {
    display: block;
  }

  .mobile_see_more {
    color: var(--primary_dark);
    font-weight: 600;

    svg {
      font-size: 22px;
      margin-right: 5px;
    }
  }
  .about-profile {
    width: 100%;
    color: var(--primary_dark);
    margin: 5px 0 0 0;
    display: flex;
    justify-content: space-around;
    box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  }

  .user-avatar {
    width: 150px;
    height: 150px;
  }
  .user-info {
    padding: 25px 0 0 0;
  }
  .profile-h6 {
    margin-top: 0;
    margin-bottom: 0.2rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .profile-edit-icon {
    margin: 25px 0 0 0;
  }
  .profile-edit-container {
    margin: 2% 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .selected_side_bar_link.active {
    height: 63px;
  }
  .about-profile {
    width: 100%;
    color: var(--primary_dark);
    margin: 5px 0 0 0;
    display: flex;
    justify-content: space-around;
    box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  }

  .user-avatar {
    width: 150px;
    height: 150px;
  }
  .user-info {
    padding: 25px 0 0 0;
  }
  .profile-h6 {
    margin-top: 0;
    margin-bottom: 0.2rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .profile-edit-icon {
    margin: 25px 0 0 0;
  }
  .profile-edit-container {
    margin: 2% 0 0;
  }
  .pageNotFound {
    width: 75%;
    margin: 15% auto;

    h1 {
      font-size: 150px;
      font-weight: bold;
      color: #7b959b;
      text-align: center;
    }

    h5 {
      font-size: 40px;
      color: #7b959b;
      text-align: center;
    }

    h6 {
      font-size: 20px;
      color: #7b959b;
      text-align: center;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 575px) {
  .previewButtons {
    position: relative;
    margin: 10px 10px 10px 0;
  }
  .m_buttons {
    margin-bottom: 15%;
  }
}

@media only screen and (max-width: 520px) {
  .hostingHidden,
  .emailHidden,
  .paymentHidden,
  .websiteHidden {
    top: 15% !important;
  }
  .pageTitle {
    font-size: 14px;
  }
  .profile-container {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 475px) {
  .admin_tables_title {
    svg {
      font-size: 26px;
      margin-right: 8px;
    }

    .backSection {
      width: 20%;
    }
  }

  .go_back {
    display: none;
  }
  .form-block {
    padding: 10px;
    padding-bottom: 12px;
  }
  .pageNotFound {
    width: 75%;
    margin: 20% auto;

    h1 {
      font-size: 100px;
      font-weight: bold;
      color: #7b959b;
      text-align: center;
    }

    h5 {
      font-size: 30px;
      color: #7b959b;
      text-align: center;
    }

    h6 {
      font-size: 15px;
      color: #7b959b;
      text-align: center;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 425px) {
  .selected_side_bar_link.active {
    height: 50px;
  }

  .about-profile {
    width: 100%;
    color: var(--primary_dark);
    margin: 5px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    box-shadow: none;
  }

  .user-avatar {
    width: 150px;
    height: 150px;
  }
  .user-info {
    padding: 25px 0 0 0;
  }
  .profile-h6 {
    margin-top: 0;
    margin-bottom: 0.2rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .profile-edit-icon {
    margin: 25px 0 0 0;
  }
  .profile-edit-container {
    margin: 2% 0 0;
  }
}

@media only screen and (max-width: 375px) {
  .online {
    font-size: 18px !important;
    display: inline-block;
    margin-left: 10px;
  }
  //.status_icon {
  //  display: none;
  //}
}