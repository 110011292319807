.data_card_block {
  background: var(--background_main) !important;
  color: var(--text_main) !important;
  box-shadow: 0 0 10px var(--neutral_main) !important;
  .data_card_actions {
    display: flex;
    justify-content: space-between;
    .mobile_action_block {
    
      button {
        color: var(--text_main);
      }
    }
  }
}
