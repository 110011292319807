.select__tour, .select_preview_tour {
  margin-top: 15px;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.6);
  width: 9%;
}

.select_preview_tour {
  width: 10%;
}

.select__tour__line {
  width: 90%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.3);
}

.map_container_style {
  width: 100%;
  height: 500px;
  background: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_location_text_style {
  text-align: center;
  font-weight: bold;
  font-size: 20px
}

.passenger_location_title {
  text-align: center;
  font-weight: bold;
  margin-top: 10px
}

@media (max-width: 1380px) {
  .select_preview_tour {
    width: 10%;
  }
}

@media (max-width: 1220px) {
  .select__tour, .select_preview_tour {
    width: 13%;
  }
  .select__tour__line {
    width: 88%;
  }
}