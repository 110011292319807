.company_settings_form {
  text-align: start;
}
.file-input {
  position: relative;
  cursor: pointer !important;
}
.file-upload {
  display: flex;
}
.choose-btn {
  width: 100px;
  height: 100%;
  background: var(--primary_main);
  color: white;
  border-radius: 4px 0 0 4px;
  cursor: pointer !important;
  z-index: 999;
}
.file-input input[type='text'] {
  width: 100%;
  height: 36px;
  padding: 1.15rem 0.75rem !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.file-input input[type='text']:focus {
  outline: none;
  border-color: #2196f3;
}

.file-input input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.border-none {
  border-radius: 0 4px 4px 0 !important;
}
.remove_img {
  width: 21px !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2%;
  cursor: pointer;
}

.pick-up-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 270px;
    object-fit: contain;
  }
}