@import '../../assets/styles/variables.scss';

.MuiDataGrid-footerContainer {
  display: none !important;
}

.pagination_block {
  width: 100%;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 3px;
  padding: 10px 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    margin-bottom: 0;
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .active > .page-link {
    background-color: var(--primary_dark);
    border-color: var(--primary_dark);
  }

  li {
    margin: 0 5px;

    a {
      border-radius: 12px;
      color: var(--primary_dark);
      font-weight: 600;
    }
  }

  .count_div {
    margin: 0 15px;

    span {
      color: var(--text_main) !important;
    }
  }

  .page_div, .dashboard-title{
    display: flex;
    align-items: center;

    .row_control {
      font-size: 16px;
      margin: 0 5px 0 0;
      .css-1nmdiq5-menu {
        margin-top: 3px;
        z-index: 999;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .pagination_block {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 757px) {
  .pagination_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .page_div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .pagination_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .count_div {
      margin: 0 5px;
    }

    li {
      margin: 0 4px 0 0;
    }

    .page_div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      ul {
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .pagination_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    .page_div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      ul {
        margin: 10px 0;
      }

      .css-1fdsijx-ValueContainer {
        padding: 0 !important;
      }
    }
  }
}
