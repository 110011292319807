.dashboardCard {
  height: auto;
  margin-right: 3%;
  margin-bottom: 15px;
}
.dashboard_items {
  width: 50%;
}
.ag-chart-wrapper {
  touch-action: auto !important;
}
@media (min-width: 1200px) and (max-width: 1265px) {
  .dashboard_items {
    width: 50%;
    .dashboard-title {
      width: 100%;
      flex-wrap: wrap;

      .date_component {
        width: 100%;
        & > div {
          width: 49%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .dashboard_items {
    width: 50%;
    .dashboard-title {
      width: 100%;
      flex-wrap: wrap;

      .date_component {
        width: 100%;
        & > div {
          width: 49%;
        }
      }
      .dashboard_h6 {
        margin-bottom: 2px;
      }
    }
  }
}
@media only screen and (max-width: 787px) {
  .dashboard_items {
    width: 100%;
    .dashboard-title {
      width: 100%;
      flex-wrap: wrap;

      .date_component {
        width: 70%;
        & > div {
          width: 49%;
        }
      }
      .dashboard_h6 {
        font-size: 19px !important;
        margin-bottom: 2px;
      }
    }
  }
}
@media only screen and (max-width: 585px) {
  .dashboard_items {
    width: 100%;
    .dashboard-title {
      width: 100%;
      flex-wrap: wrap;
      & > div {
        width: 100%;
      }
      .date_component {
        width: 100%;
        & > div {
          width: 49%;
        }
      }
      .dashboard_h6 {
        font-size: 18px !important;
        margin-bottom: 2px;
      }
    }
  }
}
