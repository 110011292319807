@import '../../assets/styles/variables.scss';

.search_container {
  .search_container_item {
  }
  .search_container_secondary {
    .search_block_secondary {
      height: 100%;
      max-height: 60px;
      padding: 8px 0;
      display: flex;
      justify-content: flex-end;
      #button-addon2 {
        background: var(--primary_main);
        color: #fff;
        border: 1px solid #ced4da;
      }
    }
  }
}

.send_button {
  margin-left: 10px;
}

.page_loger {
  .search_filter_block {
    .filter .filter_select .filter_select__control {
      width: 170px;
    }
  }
  .loggerTable {
    max-height: 490px;
    //height: 545px !important;
  }
  .MuiDataGrid-main {
    //overflow-y: scroll;
  }
}

.MuiDataGrid-virtualScroller {
  width: 1380px;
  height: 430px !important;
  margin-top: 56px;
  overflow-y: scroll !important;
  overflow-x: auto !important;
}

.MuiDataGrid-main {
  //overflow-y: scroll !important;
  //max-height: 484px;
  height: 484px;
}
.search_filter_block {
  display: flex;
  // flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 3px;
  padding: 8px 0;
  width: 100%;
  justify-content: space-between;
  .search_block {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .filter_icon {
      cursor: pointer;
      margin: 0 10px;
      padding: 6px 8px;
      position: relative;
      width: 100%;
      span {
        span {
          background-color: var(--primary_dark);
        }
      }
    }

    .export {
      svg {
        margin-left: 5px;
      }
    }
  }

  .search_comp {
    width: 85%;
  }

  .search-filter-icon {
    font-size: 28px;
    fill: #fff;
    height: 100%;
  }

  .form-control {
    padding: 0.3rem 0.7rem;
    border: none;
    border-bottom: 1px solid #ced4da;

    &:focus {
      box-shadow: none;
    }
  }
  .button_block {
    display: flex;
    justify-content: space-between;
  }
  #button-addon2 {
    border: 1px solid #ced4da;
    padding: 0.5rem 0.7rem;
    background: #14213D;
    color: #fff;
    font-size: 14px;
    &:focus-visible,
    &:active,
    &:hover {
      box-shadow: none;
      background: #FCA311;
    }
  }
  #button-addon3 {
    border: 1px solid #ced4da;
    padding: 0.5rem 0.7rem;
    background: #14213D;
    color: #fff;
    font-size: 14px;
    &:focus-visible,
    &:active,
    &:hover {
      box-shadow: none;
      background: #FCA311;
    }
  }
  #button-addon {
    border: 1px solid #ced4da;
    background: #14213D;
    color: #fff;
    margin-right: 0.5rem;
    font-size: 14px;
    &:hover {
      box-shadow: none;
      border: 1px solid #ced4da;
      background: #FCA311;
      color: #fff;
    }
  }
}
.button_block {
  justify-content: space-between;
  #button-addon4 {
    width: 100%;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.7rem;
    background: #14213D;
    color: #fff;
    font-size: 14px;
    &:focus-visible,
    &:active,
    &:hover {
      box-shadow: none;
      background: #FCA311;
    }
    svg {
      color: #fff;
    }
  }
  #button-addon5 {
    width: 100%;
    border: 1px solid #ced4da;
    background: #14213D;
    color: #fff;
    margin-right: 5px;
    font-size: 14px;
    &:hover {
      box-shadow: none;
      background: #FCA311;
    }
  }
}
.search_bottom {
  display: none !important;
}

@media (max-width: 1320px) {
  .search_container_item {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .search_container {
    .search_container_item {
    }
    .search_container_secondary {
      display: none;
    }
  }
  .search_filter_block {
    width: 100%;
    .btn {
      // span {
      //   display: none;
      // }
    }
    .search_block {
      width: 100%;
      justify-content: space-between;
      .filter_icon {
        margin: 0;
      }
    }

    .search_comp {
      width: 80%;
    }
  }
}

@media screen and (max-width: 648px) {
  .search_filter_block {
    .search_block {
      .filter_icon {
        margin: 0 1%;
      }
    }
  }
}

@media (max-width: 576px) {
  .button_block {
    padding-bottom: 8px;
  }
  .search_filter_block {
    justify-content: space-between;
    .btn {
      // span {
      //   display: none;
      // }
    }
  }
  .search_top {
    display: none !important;
  }
  .search_bottom {
    display: flex !important;
  }
  .search_comp {
    width: 100% !important;
  }
}

@media (max-width: 440px) {
  .search_filter_block {
    flex-wrap: nowrap;

    .btn {
      span {
        display: none;
      }
    }
  }
}
