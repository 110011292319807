@import '../../assets/styles/variables.scss';

#basic-button {
  text-transform: none;
}
.profile-div {
  text-align: start;
  // padding: 13px 0 0 0;
}
.profile-h5 {
  line-height: 0.7;
  margin: 0;
  color: var(--primary_light);
  font-weight: bold;
}
.profile-small {
  margin: 0;
  color: white;
  font-weight: bold;
}
.red_dot_notification {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background: #dc3545;
  position: absolute;
  top: 5px;
  right: 7px;
}
.red_dot_notification_menu {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background: #dc3545;
  position: absolute;
  top: -5px;
  right: 5px;
}
.profile_menu {
  .MuiPaper-root {
    top: 100px !important;
    width: 190px;
    background: var(--background_main);
    box-shadow: 0 0 10px var(--neutral_main);
  }
  li {
    display: flex;
    justify-content: flex-start;
    color: var(--text_main);
    .profile_menu_icon {
      margin-right: 5px;
    }
  }
  .active {
    background-color: var(--primary_light);
    color: var(--text_dark);
  }
}

@media only screen and (max-width: 1280px) {
  .profile_menu li {
      padding-left: 8px !important;
  }
}

@media (max-width: 575px) {
  .MuiPaper-root {
    top: 70px !important;
  }
}
