.main-image-dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    height: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-modal-image {
    width: 100%;
    height: 280px;
    object-fit: scale-down;
}
.images-dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    height: 300px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-image {
    width: 100%;
    height: 300px;
    object-fit: scale-down;
}