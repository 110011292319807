.web_container {
  .MuiGrid2-container {
    padding: 0;
  }

  .template_button_block {
    justify-content: flex-end;
    padding: 0 0 15px 0;

    button,
    div {
      font-size: 13px;
      color: #fff !important;
      font-weight: 600;
      background: #f88f35;
      transition: all 400ms !important;
    }

    span {
      transition: all 0.3s;
    }
  }
}

.animate__animated {
  animation-duration: 0.5s;
}

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  opacity: 1;
  transition: opacity 5000ms ease-in;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transition: opacity 5000ms ease-out;
}

.pdf-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.pdf-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 5000ms, transform 5000ms;
}

.pdf-exit {
  transform: translateY(0);
  opacity: 1;
}

.pdf-exit-active {
  transform: translateY(-20px);
  opacity: 0;
  transition: opacity 5000ms, transform 5000ms;
}

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  opacity: 1;
  transition: opacity 5000ms ease-in;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transition: opacity 5000ms ease-out;
}

.pdf-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.pdf-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 5000ms, transform 5000ms;
}

.pdf-exit {
  transform: translateY(0);
  opacity: 1;
}

.pdf-exit-active {
  transform: translateY(-20px);
  opacity: 0;
  transition: opacity 5000ms, transform 5000ms;
}


@media only screen and (max-width: 875px) {
  .web_container {
    .MuiGrid2-container {
      padding: 0;
    }

    .template_button_block {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 0 15px 0;

      button,
      div {
        width: 48%;
        font-size: 12px;
        color: #fff !important;
        font-weight: 600;
        background: #f88f35;
        margin-bottom: 13px;
        margin-left: 0;
      }

      span {
        transition: all 0.3s;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .web_content {
    .web_container {
      .generate_grid_block {
        width: 100%;
        margin: 0;

        .ad_block {
          width: 100%;
          margin: 0;
        }
      }

      .left_ads,
      .right_ads,
      .pdf_grid_item {
        padding: 0 !important;
        width: 100%;
      }

      .left_ads {
        order: 2;
      }

      .right_ads {
        order: 3;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .web_content {
    .web_container {
      .generate_grid_block {
        width: 100%;
        margin: 0;

        .ad_block {
          width: 100%;
          margin: 0;
        }
      }

      .left_ads,
      .right_ads,
      .pdf_grid_item {
        padding: 0 !important;
      }

      .left_ads {
        order: 2;
      }

      .right_ads {
        order: 3;
      }
    }
  }
}

@media only screen and (max-width: 578px) {
  .web_container {
    .MuiGrid2-container {
      padding: 0;
    }

    .template_button_block {
      display: flex;
      justify-content: space-around;
      align-items: center;
        flex-wrap: wrap;
      width: 100%;
      padding: 0 0 15px 0;

      button,
      div {
        width: 48%;
        font-size: 11px;
        color: #fff !important;
        font-weight: 600;
        background: #f88f35;
        margin-bottom: 13px;
        margin-left: 0;
      }

      span {
        transition: all 0.3s;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .web_container {
    .MuiGrid2-container {
      padding: 0;
    }

    .template_button_block {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 0 15px 0;

      button,
      div {
        width: 48%;
        font-size: 11px;
        color: #fff !important;
        font-weight: 600;
        background: #f88f35;
        margin-bottom: 13px;
        margin-left: 0;
      }

      span {
        transition: all 0.3s;
      }
    }
  }
}
