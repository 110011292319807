@import '../../assets/styles/variables.scss';

.filter_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .btn {
    svg {
      font-size: 16px;
    }
  }
}

.filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 10px;

  .filter_search_input, .status_select {
    width: 50%;
  }


  .filter_select {
    width: 40%;
    margin: 0 15px;

    &:focus-visible {
      border: none;
    }

    .filter_select__control {
      border: none;
      box-shadow: none;
    }

    .filter_select__indicators {
      cursor: pointer;
    }

    .filter_select__option {
      &:hover,
      &.filter_select__option--is-focused {
        background-color: rgba(154, 174, 179, 0.29);
      }

      &.filter_select__option--is-selected {
        background-color: var(--primary_main);
      }
    }

    .filter_select__indicator-separator {
      width: 0;
    }

    .filter_select__value-container {
      border-bottom: 1px solid #ced4da;
      border-radius: 0.375rem;
    }

    .filter_select__indicator {
      svg {
        margin-left: 0;
      }

      padding-left: 0;
    }

    .filter_select__placeholder {
      font-size: 16px;
    }
  }

  .filter-date-block {
    display: flex;
    justify-content: space-between;
    width: 60%;

    .MuiStack-root {
      width: 49%;
    }
  }
}
.filter_btn {
  margin-left: 2px;

  &:hover {
    background-color: #FCA311 !important;
  }

  span {
    font-size: 12px;
    color: #fff;
  }

  svg {
    font-size: 16px !important;
    fill: #fff !important;
  }
}

@media only screen and (max-width: 1023px) {
  .filter {
    width: 96%;
    margin-left: 0;
  }
  .filter_btn {
    margin-left: 0 !important;
  }
  .filter_block {
    width: 85%;
  }
  .filter.date-filter {
    display: block;

    .filter-date-block {
      width: 85%;
      float: left;
    }

    svg {
      width: 6%;
    }
  }
}

@media (max-width: 1320px) {
  .filter_block {
    .react-select.filter_select__control {
      min-height: 30px;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 576px) {
  // .filter_btn {
  //   margin-left: 0 !important;
  //   width: 15%;
  // }
  // .filter_block {
  //   width: 85%;
  // }
  // .filter.date-filter {
  //   display: block;

  //   .filter-date-block {
  //     width: 85%;
  //     float: left;
  //   }

  //   svg {
  //     width: 6%;
  //   }
  // }
}



@media only screen and (max-width: 500px) {
}

@media only screen and (max-width: 380px) {
  .filter.date-filter {
    display: block;

    .filter-date-block {
      width: 93%;
      float: left;

      input[type='date'] {
        font-size: 12px;
        //background-image: none !important;
        background-size: 14px;
      }
    }

    svg {
      width: 6%;
    }
  }
}
