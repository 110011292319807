.banner {
  background-image: url('./banner_img/bannerbg.png');
  background-repeat: no-repeat;
  height: 310px;
  .web_content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 50px;
      font-weight: bold;
      color: white;
    }
  }
}
.main {
  .web_content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 5% 6%;
    .search_div {
      .search_p {
        font-size: 1.75rem;
        color: #374253;
        font-weight: 600;
      }
      .input_div {
        display: flex;
        flex-wrap: wrap;
        input[type='text'] {
          text-indent: 10px;
          width: 100%;
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          margin-top: 20px;
          border-radius: 5px;
          outline: 1px #f88f35;
        }
        .input_small {
          width: 100%;
          height: 15px;
          color: rgb(229, 4, 4);
        }
        button {
          width: 100%;
          height: 40px;
          background-color: #f88f35;
          border: none;
          transition: 0.4s;
          margin-top: 5px;
          border-radius: 5px;
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0 0 10px,
              rgba(0, 0, 0, 0.34) 0 0 20px;
          }
          span {
            color: white;
            transition: all 0.3s;
          }
        }
      }
    }
    .check_form {
      width: 100%;
    }
  }
}
