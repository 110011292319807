@import '../../assets/styles/variables.scss';

.mobile_side_bar {
  display: none;
}
.menu_bottom {
  display: none;
}

.no-scroll {
  overflow: hidden !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  z-index: 444;
}
.admin_sidebar_block {
  width: 100%;
}
.admin_side_bar {
  background: var(--background_main);
  color: var(--text_main);
  border-radius: 4px;
  border: 1px solid var(--neutral_main);
  box-shadow: 0 0 10px var(--neutral_main);
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 686px;
  .list_block {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.side_bar_tab {
  border-bottom: 1px solid var(--neutral_light) !important;
  span {
    font-weight: bold;
  }
  svg {
    font-size: 16px;
    fill: var(--text_main);
  }
  .icon_block {
    min-width: 24px;
  }
}
.Mui-selected {
  background-color: var(--primary_light) !important;
  color: var(--text_dark) !important;
  // svg {
  //   fill: var(--text_dark);
  // }
}
@media only screen and (max-width: 1199px) {
  .menu_bottom {
    display: block;
  }
  .mobile_side_bar {
    display: block;
  }
  .admin_sidebar_block {
    display: none;
  }
}
