@import '../../assets/styles/variables.scss';

.admin_tables_title {
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid var(--neutral_main);
  border-radius: 4px;
  width: 100%;
  background: var(--background_main);
  box-shadow: 0 4px 20px rgba(75, 102, 197, 0.1);
  margin-bottom: 10px;
  justify-content: space-between;
  position: relative;
  padding: 0 1% 0 0;

  .admin_tables_title_div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .pageTitle {
    color: var(--primary_dark);
  }
  .action_block {
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .action_block_item {
      margin-left: 5px;
      padding: 1px 5px;
      &:hover {
        svg, span, .backSection {
          transition: all 250ms ease-in-out ;
          color: #FCA311 !important;
        }
      }
      .action_title {
        padding-left: 5px;
      }
    }
  }
  .parrentPage {
    text-decoration: none;
    color: var(--primary_dark);

    &:hover {
      color: #FCA311 !important;
    }
  }

  svg {
    font-size: 24px;
    color: var(--primary_dark);
  }
  span {
    font-weight: 600;
    color: var(--primary_dark);
  }

  .backSection {
    color: var(--primary_dark);
    font-weight: bold;
    cursor: pointer;
    text-align: end;
    width: 20%;
    &:hover {
      span {
        svg, span {
          transition: all 250ms ease;
          color: #FCA311 !important;        }
      }
    }
  }

  .add-new-drop-down-button {
    font: 14px/20px 'Montserrat', sans-serif;
    text-transform: capitalize;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  ul[class='MuiList-root'] {
    li[clas='add-new-drop-down-options'] {
      font: 14px/20px 'Montserrat', sans-serif !important;
    }
  }
}

@media (max-width: 1023px) {
  .admin_tables_title {
    min-height: 48px;
    height: auto;
    padding: 10px 1% 10px 0;
  }
}

@media (max-width: 667px) {
  .admin_tables_title {
    svg {
      font-size: 26px;
      margin-right: 8px;
    }
    .backSection {
      width: 8%;
    }
  }
  .go_back {
    display: none;
  }
}
@media (max-width: 600px) {
  .action_block {
    .action_block_item {
      span {
        display: none;
      }
    }
  }
}
