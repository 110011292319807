.web_footer_block {
  width: 100%;
  background-color: black;

  .web_container {
    display: flex;
    max-width: 100%;
    width: 1430px;
    margin: 0 auto;
  }

  .web_footer_grid {
    width: 100%;
    margin: 0;

    .footer_flex {
      display: flex;
      justify-content: center;

      .nav_block {
        display: flex;
        flex-direction: column;
        padding: 30px 0 0;

        .footer_bar {
          color: white;
          font-weight: bold;
        }

        .soc_block {
          display: flex;
          flex-direction: row;

          .footer_links_soc {
            margin-top: 20px;
            margin-right: 10px;
            text-decoration: none;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 400ms;

            svg {
              font-size: 24px;
              transition: all 400ms;
            }

            &:hover {
              background: white;

              .socialSvgFB {
                color: #3a5998;
              }

              .socialSvgTR {
                color: #589442;
              }
            }
          }

          .facebookCustom {
            background: #3a5998;
            padding: 7px;
            border-radius: 266px;
            width: 40px;
          }

          .tripAdvisor {
            background: #589442;
            padding: 7px;
            border-radius: 266px;
            width: 40px;
          }
        }
      }

      .footer_links {
        margin-top: 20px;
        text-decoration: none;
        color: white;
        transition: 0.4s;

        &:hover {
          text-decoration: none;
          color: #f83f2b;
        }
      }

      .footer_links:last-child {
        margin-bottom: 50px;
      }


    }
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  .web_footer_block {
    flex-direction: column;

    .web_footer_grid > div {
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    .web_footer_grid > div > .nav_block {
      align-items: center;
      padding: 0 !important;
      .soc_block {
        margin-bottom: 35px !important;
      }
    }

  }

  .footer_bar {
    margin-top: 10px;
  }

}

