.web_container {
  .ad_card_content_block {
    padding: 8px 16px !important;
  }
  .card_button {
    background-color: #408c3d;
    color: #fff !important;
    &:hover {
      background-color: #408c3d;
      color: #fff !important;
    }
  }
}
