@import '../../assets/styles/variables.scss';

.mobile_view {
  height: auto;
  .icon_block_mobile_view {
    margin-bottom: 5px;
    margin-top: 10px;
    .mode_block {
      display: flex;
      justify-content: flex-end;
      svg {
        font-size: 24px;
        margin: 0 5px;
        color: var(--text_main) !important;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .see_more {
    display: none;
  }
}
