@import '../../assets/styles//variables.scss';

.footer_block {
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid var(--neutral_main);
  border-radius: 4px;
  width: 100%;
  margin: 1% auto;
  background: var(--background_main);
  box-shadow: 0 4px 20px rgb(75 102 197 / 10%);
}
.footer_text {
  font-size: 16px;
  font-weight: 600;
  color: var(--text_main);

  margin-bottom: 0;
}
.footer_version_text {
  font-size: 16px;
  font-weight: 600;
  color: var(--text_main);
  margin-bottom: 0;
}
.footer_content {
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: space-between;
}


.footer_link {
  text-decoration: none;
  color: #14213D;
  transition: all 250ms ease-in-out ;
}
.footer_link:hover {
  color: #FCA311;
}
.modal-footer {
  padding: 9px 9px !important;
}

.side_bar_footer {
  .footer_block {
    width: 100%;
    margin: 0;
  }
  .footer_text {
    font-size: 11px;
  }
  .footer_version_text {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1023px) {
  .wrapper {
    .footer_block {
      bottom: 0;
      position: absolute;
    }
  }
}

@media only screen and (max-width: 380px) {
  .footer_content {
    display: flex;
    align-items: center;
    vertical-align: center;
    flex-wrap: wrap;
    .footer_text {
      width: 100%;
    }
  }
}
