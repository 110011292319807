.pdf_template {
  .MuiGrid2-container {
    width: 100%;
    margin: 0 auto;
    .MuiGrid2-direction-xs-row {
      min-height: 36px;
    }
  }
  .MuiGrid2-direction-xs-row {
    margin-bottom: 8px;
    text-align: center;
    font-weight: 600;
    white-space: normal;
    word-wrap: break-word;
    font-size: 14px;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
.map_iframe {
  div {
    iframe {
      width: 100%;
      height: 200px;
    }
  }
}

@media screen and (max-width: 992px){
  .pdf_footer_item {
    font-size: 0.9em !important;
  }
}

@media screen and (max-width: 768px){
  .pdf_footer_item {
    font-size: 0.8em !important;
  }
}

@media screen and (max-width: 600px) {
  .pdf_template {
    font-size: 0.9em;
    // margin-left: 3%;
  }
}

@media screen and (max-width: 450px) {
  .pdf_template {
    font-size: 0.7em;
    // margin-left: 4%;
  }
  .pdf_footer_item {
    font-size: 0.8em;
  }
}
