@import "./variables";

.login-page {
  height: 100vh;
  background-image: url("../../../public/images/login_page_bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login_page_overlay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.25);
}

.login {
  box-shadow: 0 4px 20px rgb(154 174 179 / 31%);
  background-color: rgb(0, 0, 0, 0.5);
  padding: 30px 50px;
  width: 30%;
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: 640px;
}

.login:has(.footer_container) {
  align-content: stretch;
  align-items: flex-end;
  flex-wrap: wrap;
}

.footer_container {
  width: 100%;

  .link_styles {
    color: #fca311;
    display: block;
    text-align: end;

    .website_links {
      cursor: pointer;
      margin-left: 25px;
    }
  }
}

.login-form {
  input {
    color: white;
    background: rgb(0, 0, 0, 0);
    border: 1px solid #e5e5e5a8;

    &:focus {
      color: white;
      background: rgb(0, 0, 0, 0) !important;
      outline: none;
    }

    &::placeholder {
      color: #c4c3c3;
    }
  }

  .input-group-text {
    position: absolute;
    right: 5px;
    top: 5px;
    background: transparent;
    border: none;
    color: white;
    z-index: 6 !important;
  }

  .forgot_button {
    color: white;
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
}

.remember-form {
  margin-top: 20px;

  span {
    margin-left: 4px;
    font-size: 15px;
  }
}

.input-icon-group {
  width: 100%;
  height: 50px !important;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    width: 49%;

    input {
      width: 100% !important;
    }
  }
}

.password-input-icon-group {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    width: 100%;
    padding: 0;

    input {
      width: 100% !important;
    }

    .input-group-text {
      position: absolute;
      right: 5px;
      top: 5px;
      background: transparent;
      border: none;
      color: white;
      z-index: 6 !important;
    }
  }
}

.sign_up {
  input {
    width: 100%;
    color: white;
    background: rgb(0, 0, 0, 0);
    border: 1px solid #e5e5e5a8;

    &:focus {
      color: white;
      background: rgb(0, 0, 0, 0) !important;
      outline: none;
    }

    &::placeholder {
      color: #c4c3c3;
    }
  }

  .terms_conditions {
    display: flex;
    align-items: center;
    padding: 0;
    input[type="checkbox"] {
      width: 1em;
      appearance: auto;
      border-radius: 4px;
      cursor: pointer;
      margin: 0;
    }

    label {
      user-select: none;
      margin-left: 5px;
      color: white;
      cursor: pointer;

      .link_terms_condition {
        color: #fca311;
        cursor: pointer;
      }
    }
  }
  .required-terms {
    input[type="checkbox"] {
      appearance: none;
      border: 1px solid #EE204D;
      cursor: pointer;
      margin: 0;
    }
    label {
      color: #EE204D !important;
      cursor: pointer;
      .link_terms_condition {
        color: #fca311;
        cursor: pointer;
      }
    }
  }
  .company_name {
    margin: 0 0 15px 0;
  }

  .chosen-select {
    border: none;
    padding: 0;
    background: transparent;

    .react-select__control {
      color: white;
      background: rgb(0, 0, 0, 0);
      border: 1px solid #e5e5e5a8;
    }

    .react-select__single-value,
    .react-select__input-container {
      color: white !important;
    }

    .react-select__placeholder,
    .react-select__indicator {
      color: #e5e5e5a8;
    }
  }

  .border-red {
    .react-select__control {
      border: 1px solid #dc3545 !important
    }
  }
}

.phone-input-icon-group {
  width: 100%;
  height: 50px !important;

  input[type="number"] {
    width: 100% !important;
  }
}

.login-form input {
  width: 49% !important;
  height: 40px !important;
}

.forget-pass {
  .link {
    text-decoration: none;
  }

  margin-top: 20px;
}

.login-button-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: center;
  justify-content: space-between;

  button {
    width: 100% !important;
  }

  .company_details_btn {
    width: 46% !important;
    text-transform: capitalize;
  }

  .or_span {
    width: 100%;
    color: white;
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }

  small {
    width: 100%;
    color: white;
    text-align: end;
  }
}
.preview_submit_btn {
  text-transform: capitalize;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  span {
    color: white;
    font-size: 18px;
  }
}
.preview_submit_btn:active {
  transform: scale(0.98);
}
.login_submit_btn {
  width: 165px !important;
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid #e5e5e5a8 !important;
  text-transform: capitalize;
  font-family: "Montserrat", serif !important;
  // box-shadow: 0px 4px 20px rgba(75, 102, 197, 0.1);
  border-radius: 4px;
  background-color: #14213d !important;
  color: white;
  font-weight: bold;
  width: 302px;
  height: 40px;
  cursor: pointer;

  span {
    color: white;
    font-size: 18px;
  }
}

.login_button.login_submit_btn {
  margin-top: 10px;
}

.login_submit_btn:active {
  transform: scale(0.98);
}

.login-page_title {
  line-height: 35px;
  font-size: 2.5em;
  color: white;
  text-align: center;
  margin-bottom: 1.7rem;
}

.login-page-text {
  line-height: 35px;
  font-size: 1em;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.login-page_logo {
  line-height: 35px;
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 2rem;
}

.signup-page_h6 {
  line-height: 35px;
  font-size: 1.3em;
  color: white;
  justify-content: start;
}

.verify_form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  button {
    width: 50% !important;
  }
}

.login_text_p {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 0.930em;
  color: white;
  margin-top: 5px;
  margin-bottom: 0;
  cursor: pointer;

  strong {
    margin: 0 0 0 3px;
  }

  .login_btn_small {
    margin-left: 3px;
    font-size: 14px;
    color: #fca311;

    a {
      color: #fca311;
      font-size: 14px;
    }
  }
}

.login_btn_small {
  font-size: 14px;
  color: #fca311;

  a {
    color: #fca311;
    font-size: 14px;
  }

  a:hover {
    color: #fca311;
  }
}

.pass_input {
  position: relative;
}

.error_message {
  color: #ff0000c9;
}

.required-message {
  width: 100%;
  margin: 0.1rem 0 0.2rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid_username_password {
  text-align: center;
  padding: 10px;
  background-color: #ff000012;
  margin-top: 17px;
}

.forgot-pass-link {
  padding: 0 5px;
  display: flex;
  justify-content: space-between;

  span span {
    color: var(--primary_dark);
    cursor: pointer;
    text-decoration: underline;
  }
}

.required-message {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.auth-text {
  width: 100%;
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
}

.loader {
  width: 15px;
  height: 28px;
  margin-left: 2px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #fff 90%, #0000) 0 /
    calc(100% / 3) 75% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

@media (max-width: 1360px) {
  .login {
    padding: 25px;
  }
}
@media (max-width: 1380px) {
  .login {
    width: 35%;
    padding: 20px;
  }
}

@media (max-width: 1110px) {
  .login {
    width: 35%;
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .login {
    padding: 5px;
    width: 45%;
  }
  .login_page_input_group {
    width: auto !important;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .login {
    width: 54%;
  }
}

@media (max-width: 576px) {
  .login-page {
    .login {
      width: 100%;
      padding: 20px 3%;

      .login-button-div {
        .company_details_btn {
          width: 100% !important;
        }

        .company_details_btn:nth-child(1) {
          order: 2;
        }

        .company_details_btn:nth-child(2) {
          order: 1;
        }

        .login_text_p:nth-child(3) {
          order: 3
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .login-page {
    .login {
      width: 100%;
      padding: 20px 0;
    }
  }
}
