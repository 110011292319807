.web_header_block {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .web_container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1430px;
    margin: 0 auto;
    height: 100%;

    .web_header_grid {
      width: 100%;
      height: 100%;
      .web_header_grid_item {
        height: 100%;
      }
    }

    .logo_block {
      display: flex;
      width: 100%;
      height: 100%;
      .logo_img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }

    .nav_bar {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .nav_ul {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav_li {
          display: flex;
          flex-direction: column;
          color: black;
          text-decoration: none;
          justify-content: center;
          margin-right: 4%;
          list-style-type: none;

          a {
            color: black;
            list-style-type: none;
            text-decoration: none;
            font-size: 17px;
            transition: 0.4s;

            &:hover {
              color: #f83f2b;
            }
          }
        }
      }
    }

    .button_block {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 0;

      a {
        text-decoration: none;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          font-size: 17px;
          width: 100%;
          height: 100%;
          background-color: #f83f2b;
          color: white;
          border: none;
          transition: 0.3s;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0 0 10px,
              rgba(0, 0, 0, 0.34) 0 0 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .web_header_block {
    .web_container {
      .web_header_grid {
        .logo_block {
        }

        .button_block {
          a {
            .button {
              font-size: 16px;
              width: 218px;
              height: 55px;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .web_header_block {
    .web_container {
      padding: 0 20px;
      .nav_bar {
        .nav_ul {
          .nav_li {
            a {
              font-size: 14px;

              &:hover {
              }
            }
          }
        }
      }
      .web_header_grid {
        .logo_block {
        }

        .button_block {
          a {
            .button {
              font-size: 16px;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .web_header_block {
    .web_container {
      .nav_bar {
        .nav_ul {
          .nav_li {
            a {
              font-size: 12px;

              &:hover {
              }
            }
          }
        }
      }
      .web_header_grid {
        .logo_block {
        }

        .button_block {
          a {
            .button {
              margin-right: 10px;
              margin-left: 6px;
              font-size: 16px;
              width: 100%;
              height: 100%;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .web_header_block {
    .web_container {
      .web_header_grid > .first {
      }

      .web_header_grid > .last {
        .button_block {
          a {
            .button {
              margin-right: 10px;
              font-size: 14px;
              width: 100%;
              height: 100%;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .web_header_block {
    height: 200px;
    .web_container {
      .web_header_grid {
        height: auto;
        .web_header_grid_item {
          height: auto;
        }
      }

      .web_header_grid > .second {
        order: 3;
      }
      .nav_bar {
        .nav_ul {
          justify-content: space-between;
        }
      }
      .button_block {
        padding: 40px 0;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .web_header_block {
    .web_container {
      .web_header_grid {
        .logo_block {
        }

        .button_block {
          a {
            .button {
              margin-right: 10px;
              font-size: 12px;
              width: 48px;
              height: 30px;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
      .nav_bar {
        .nav_ul {
          .nav_li {
            a {
              color: black;
              list-style-type: none;
              text-decoration: none;
              font-size: 12px;
              transition: 0.4s;

              &:hover {
                color: #f83f2b;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .web_header_block {
    .web_container {
      .web_header_grid {
        .button_block {
          a {
            .button {
              margin-right: 10px;
              font-size: 12px;
              width: 48px;
              height: 30px;
              background-color: #f83f2b;
              color: white;
              border: none;
              transition: 0.4s;
            }
          }
        }
      }
      .nav_bar {
        .nav_ul {
          .nav_li {
            a {
              font-size: 9px;
              font-weight: 600;
              &:hover {
                color: #f83f2b;
              }
            }
          }
        }
      }
    }
  }
}
