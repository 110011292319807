@import './variables';

.activation-page-load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.activate-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .activate {
    width: 30%;
    border: 1px solid #9aaeb35c;
    box-shadow: 0 4px 20px rgb(154 174 179 / 31%);

    .activate-title-container {
      background-color: var(--primary_main);
      width: 100%;
      height: 100px;

      .header-logo {
        margin: 25px;
      }

      .activate-title-name {
        color: white;
      }
    }

    .activate_submit_btn {
      margin-top: 20px;
      box-shadow: 0 5px 10px rgb(119, 119, 119);
      border-radius: 15px;
      background-color: var(--primary_main);
      color: white;
      font-size: 18px;
      font-weight: 500;
      height: 50px;
      cursor: pointer;
      font-family: system-ui;
      width: 50%;
      &:active {
        transform: scale(0.98);
      }
    }

    .activate-form-container {
      padding: 5%;
      margin: 5% 0;

      .activate-form {
        div {
          width: 100%;
          div {
            width: 5%;
          }
        }
        form {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        .activate_submit_btn {
          width: 45%;
        }

        input {
          width: 100%;
          height: 55px;
          font-family: system-ui;
        }
        .activate-account-email {
          background-color: #e5e5e5;
        }
      }
    }

    .activate-form-container.error {
      margin: 0;
      padding: 30px 30px;
      .activate_submit_btn {
        width: 35%;
      }
    }

    .activate-footer-container {
      height: 100px;
      text-align: center;

      .line-div {
        width: 90%;
        border: 1px solid #acd2c3;
        display: inline-block;
      }

      .social-div {
        p {
          .social-icons {
            margin: 0 10px 0 0;
          }
        }

        a {
          text-decoration: none;
          color: black;
          cursor: pointer;
        }
      }
    }

    .activate-messages-div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .activation-page-message {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 1220px) {
  .activate-page {
    .activate {
      width: 35%;
    }
  }
}

@media (max-width: 992px) {
  .activate-page {
    .activate {
      width: 50%;
    }
  }
}

@media (max-width: 760px) {
  .activate-page {
    .activate {
      width: 75%;
    }
  }
}

@media (max-width: 425px) {
  .activate-page {
    .activate {
      width: 100%;
    }
  }
}
