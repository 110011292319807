// UpdatePasswordModal
.pass_eye {
  background: none;
  background-color: transparent !important;
  border-left: none;
}

.pass_input {
  // border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.deleteConfirmText {
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.email-modal-form {
  .form-label {
    margin: 8px 0;
  }
}

.radio_buttons {
  font-size: 18px;
}

.report_radio_button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.325rem;

  input[type=radio] {
    margin-right: 3px;
    background-image: none;
    background-color: #d1d4d6;
  }

  input[type=radio]:checked {
    background-image: none;
    background-color: #14213D;
  }

  label {
    margin-top: 5px;
  }
}

.send_email_radio_button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.325rem;

  input[type=radio] {
    margin-right: 3px;
    background-image: none;
    background-color: #d1d4d6;
  }

  input[type=radio]:checked {
    background-image: none;
    background-color: #14213D;
  }

  label {
    margin-top: 5px;
  }
}