.list_block_mobile {
  border: 1px solid var(--neutral_main);

  border-radius: 10px;
  margin-top: 10px !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .list_block_item_mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .list_id_ind {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .list_status_ind {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .list_status_ind_none {
    display: none;
  }

  .list_action_block {
    padding-top: 0;
    justify-content: flex-end;

    button {
      color: var(--text_main) !important;
      svg {
        font-size: 18px;
      }
    }
  }

  .list_item {
    width: 30%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  p {
    margin-top: 5px;
    color: var(--text_main) !important;
  }

  span {
    font-size: 14px;
    color: var(--text_main) !important;
  }
}

@media (max-width: 425px) {
  .list_block_mobile {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
      font-size: 12px;
    }

    p {
      font-size: 12px;
    }
  }
}
