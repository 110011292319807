@import './variables.scss';
@import '~bootstrap/scss/bootstrap';
//@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './admin_page';

$green: #86bb71;
$blue: #94c2ed;
$orange: #e38968;
$gray: #92959e;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_container {
  width: 95%;
  max-width: 1950px;
  margin: 0 auto;
}

body {
  font: 14px/20px 'Montserrat', sans-serif;
  background: var(--background_main) !important;
}

.web_container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.disabled {
  color: var(--neutral_dark) !important;
  fill: var(--neutral_dark) !important;
  opacity: 0.7;
  cursor: default;
}

.react-tel-input .form-control {
  width: 100%;
  height: 37.7px !important;
}

.phone_width {
  width: 100% !important;
}

.flag-dropdown, .react-tel-input .form-control {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.status_icon {
  // color: rgb(56, 56, 56);
  border-width: 1px;
  border-style: solid;
  // border-color: rgb(56, 56, 56);
  border-radius: 14px;
  font-size: 12px;
  padding: 0 6px;
  box-shadow: 0px 0px 4px 1px;
  margin-left: 15px;
}


div[id="currency_id"] {
  background-color: white !important;

  div[aria-disabled="true"] {
    background-color: white !important;
  }

  div[aria-disabled="true"] {
    div:nth-child(1) {
      color: black !important;
    }
  }
}


.chat-wrapper {
  background: #c5ddeb;
  color: white;
  padding: 40px 0;
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  .container {
    margin: 0 auto;
    width: 750px;
    background: #444753;
    border-radius: 5px;
  }

  .people-list {
    width: 260px;
    float: left;
    background: #444753;
    position: relative;

    .search {
      padding: 20px;
    }

    input {
      border-radius: 3px;
      border: none;
      padding: 14px;
      color: white;
      background: #6a6c75;
      width: 90%;
      font-size: 14px;
    }

    .fa-search {
      position: relative;
      left: -25px;
    }

    ul {
      padding: 20px;
      height: 770px;
      overflow-y: auto;

      li {
        padding-bottom: 20px;

        a {
          color: inherit;
          display: flex;
        }
      }
    }

    img {
      float: left;
    }

    .about {
      float: left;
      margin-top: 8px;
    }

    .about {
      padding-left: 8px;
    }

    .status {
      color: $gray;
    }
  }

  .chat {
    width: 490px;
    float: left;
    background: #f2f5f8;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    color: #434651;

    .chat-header {
      padding: 20px;
      border-bottom: 2px solid white;
      min-height: 95px;

      img {
        float: left;
      }

      .chat-about {
        float: left;
        padding-left: 10px;
        margin-top: 6px;
      }

      .chat-with {
        font-weight: bold;
        font-size: 16px;
      }

      .chat-num-messages {
        color: $gray;
      }

      .fa-star {
        float: right;
        color: #d8dadf;
        font-size: 20px;
        margin-top: 12px;
      }
    }

    .chat-history {
      padding: 30px 30px 20px;
      border-bottom: 2px solid white;
      overflow-y: scroll;
      height: 575px;

      .message-data {
        margin-bottom: 15px;
      }

      .message-data-time {
        color: lighten($gray, 8%);
        padding-left: 6px;
      }

      .message {
        color: white;
        padding: 18px 20px;
        line-height: 26px;
        font-size: 16px;
        border-radius: 7px;
        margin-bottom: 30px;
        width: 90%;
        position: relative;

        &:after {
          bottom: 100%;
          left: 7%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: $green;
          border-width: 10px;
          margin-left: -10px;
        }
      }

      .my-message {
        background: $green;
      }

      .other-message {
        background: $blue;

        &:after {
          border-bottom-color: $blue;
          left: 93%;
        }
      }
    }

    .chat-message {
      padding: 30px;

      textarea {
        width: 100%;
        border: none;
        padding: 10px 20px;
        font: 14px/22px 'Lato', Arial, sans-serif;
        margin-bottom: 10px;
        border-radius: 5px;
        resize: none;
      }

      .fa-file-o,
      .fa-file-image-o {
        font-size: 16px;
        color: gray;
        cursor: pointer;
      }

      button {
        float: right;
        color: $blue;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        font-weight: bold;
        background: #f2f5f8;

        &:hover {
          color: darken($blue, 7%);
        }
      }
    }
  }

  .online,
  .offline,
  .me {
    margin-right: 3px;
    font-size: 10px;
  }

  .online {
    color: $green;
  }

  .offline {
    color: $orange;
  }

  .me {
    color: $blue;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .float-right {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}

.loading-wrapper {
  transform: scale(0.4);

  .circle {
    width: 30px;
    height: 30px;
    background-color: #242424;
    border-radius: 50%;
    display: inline-block;

    position: absolute;

    animation: changeColor 300ms ease;
    animation-iteration-count: infinite;
    animation-duration: 1s;

    /* For slower animation */
    /*   animation-duration: 3s;  */
  }

  .circle:nth-child(1) {
    top: 0;
    left: 0;
  }

  .circle:nth-child(2) {
    top: 19px;
    left: 32px;

    animation-delay: 0.166s;
  }

  .circle:nth-child(3) {
    top: 54px;
    left: 32px;

    animation-delay: 0.33s;
  }

  .circle:nth-child(4) {
    top: 73px;
    left: 0;

    animation-delay: 0.499s;
  }

  .circle:nth-child(5) {
    top: 54px;
    left: -32px;

    animation-delay: 0.666s;
  }

  .circle:nth-child(6) {
    top: 19px;
    left: -32px;

    animation-delay: 0.833s;
  }

  @keyframes changeColor {
    0% {
      background-color: #242424;
    }

    20% {
      background-color: white;
    }

    100% {
      background-color: #242424;
    }
  }

  .child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    height: 93px;
  }
}

.avatar {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background-color: white;
  border: 3px solid #86bb71;
  object-fit: cover;
}

.MuiDataGrid-cell {
  img {
    width: 100%;
    height: 100%;
  }
}

.accordion_title {
  font-weight: 600 !important;
  margin-bottom: 0px;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

//.input-group:not(.has-validation)
//  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
//    .form-floating
//  ) {
//  border-radius: 5px;
//}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[name='days-labor'] {
  padding: 7px !important;
}

.form-label {
  color: var(--text_dark) !important;
}

input,
.form-control {
  color: var(--text_main);
}

.react-select__single-value {
  color: var(--text_main) !important;
}

.button {
  color: var(--text_main);
}

.custom-invalid-feedback {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.note_for_preview {
  color: #585858;
  margin-bottom: 0;
  margin-top: 10px;
}

.history_info_div {
  p {
    word-break: break-all;
  }
}

@media screen and (max-width: 992px) {
  .modal-xl {
    max-width: 800px;
  }
}

/* For lg screens */
@media (min-width: 992px) {
  .custom-col-lg {
    flex: 0 0 12.5%; /* 1.5 out of 12 columns = 12.5% */
    max-width: 12.5%;
  }
}

.react-currency-select__indicators {
  .react-currency-select__indicator {
    padding: 8px 5px;
  }
}

//@media screen and (max-width: 787px) {
//  .input-group:not(.has-validation)
//    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
//      .form-floating
//    ) {
//    width: 100%;
//  }
//}
//@media screen and (max-width: 575px) {
//  .input-group:not(.has-validation)
//    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
//      .form-floating
//    ) {
//    width: 100%;
//  }
//}
