@import '../../assets/styles/variables.scss';

.header_block {
  width: 100%;
  background-color: #14213D;
  margin-bottom: 45px;
  z-index: 5000;
  position: relative;
}

.mobile_view {
  display: none !important;
}

.modal-backdrop {
  opacity: 0;
}

.header_title {
  color: white;
  font-size: 50px;
}

.header_content {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.header_logo {
  height: 65px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  img {
    width: 60%;
    height: 100%;
  }
}

.logout_icon {
  font-size: 35px;
  padding-right: 4px;
  color: white;
}

.log_out_link {
  position: absolute;
  bottom: 24%;
  font-weight: 600;
  font-size: 18px;
  padding-left: 20px;
  width: 17%;
  background-color: white;
  z-index: 99;
}

.body_html {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1950px;
  margin: 0 auto;
}

.show_on_mobile {
  display: none;
}

.hide_on_mobile {
  display: flex;
  flex-wrap: wrap;
  font-size: 17px;
  font-weight: 600;
}

.selected_side_bar_link:hover {
  color: var(--primary_dark);
  text-decoration: none;
  background: var(--primary_light);
}

.selected_side_bar_menu_link:hover {
  color: var(--primary_dark);
  text-decoration: none;
  background: var(--primary_light);
}

.logout_block {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 18px;
    margin: 0 15px;
    color: rgb(255, 255, 255)
  }
}

.burger {
  display: none;
}

.menu_mobile {
  .hide_on_mobile {
    display: none;
  }
}

.logout_block_menu {
  position: absolute;
  top: 0;
  font-size: 16px;
  width: 100%;
  background-color: var(--primary_main);
}

.navbar-ul {
  cursor: pointer;
  padding: 0 10px;
}

.navbar-li {
  color: white;
  padding: 5px 0 5px 5px;
  font-weight: bold;
}

.navbar-li:hover {
  background-color: #59848a;
  color: white;
  font-weight: bold;
}

.navbar-li-active {
  background-color: #59848a;
  color: white;
  padding: 5px 0 5px 5px;
  font-weight: bold;
}

@media screen and (max-width: 1199px) {
  .header_block {
    margin-bottom: 0;
  }
  .admin_tables_block {
    width: 96%;
  }
  .logout_block {
    display: none;
  }
  .burger {
    display: block;
    margin-right: 3%;
    z-index: 10;
  }
}

@media screen and (max-width: 1023px) {
  .mobile_view {
    display: block !important;
    height: auto;
  }

  .modal-backdrop.show,
  .modal-dialog {
    display: flex;
  }

  .menu_mobile {
    display: block;
    position: absolute;
    top: 100px;
    right: 0;
    width: 65%;
    box-shadow: none;
    z-index: 10;

    .admin_dashboard_bar {
      box-shadow: none;
    }

    .hide_on_mobile {
      display: block;
      right: 0px;
      width: 100%;
      box-shadow: none;
      height: auto;
    }

    .mobile_header_acc_menu {
      margin-top: 10px;
    }

    .logOut_mobile {
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;

      .navbar-li-mobile {
        padding: 15px 0 15px 18px;
        color: rgba(0, 0, 0, 0.8);
      }

      .navbar-li-mobile.mobile_header_acc {
        background-color: var(--primary_main);
        color: white;
        width: 100%;
        font-size: 1.1em;
      }
    }
  }

  .web_table {
    display: none !important;

    div {
      background: none !important;
    }
  }

  .body_html {
    display: block;
  }

  .show_on_mobile {
    display: flex;
    height: 100px;
    justify-content: space-around;

    .link_mobile {
      border: none !important;
    }
  }

  .hide_on_mobile {
    display: none;
  }

  .show_on_mobile {
    display: block;
  }

  .css-1oqqzyl-MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .logout_icon {
    font-size: 24px;
    padding-right: 4px;
    color: black;
  }

  .layout {
    position: absolute;
    width: 100%;
    height: 1000%;
    background-color: #00000082;
  }

  .selected_side_bar_link:hover {
    background-color: #fff;
  }

  .selected_side_bar_link.active:hover {
    background-color: rgba(154, 174, 179, 0.29);
  }
}

@media screen and (max-width: 787px) {
  .header_content {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100px;
    flex-wrap: wrap;
  }

  .header_logo {
    width: 40% !important;
    // height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .burger {
    svg {
      font-size: 45px !important;
      //vertical-align: inherit !important;
    }
  }
  .selected_side_bar_link:hover {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    background: none;
  }

  .selected_side_bar_menu_link:hover {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    background: none;
  }
}

@media screen and (max-width: 575px) {
  .header_content {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 75px;
    flex-wrap: wrap;
  }

  .header_logo {
    width: 55% !important;
    // height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    img {
      object-fit: contain;
    }
  }
  .burger {
    svg {
      font-size: 35px !important;
      vertical-align: inherit !important;
    }
  }

  .admin_side_bar_mobile .link_mobile span {
    font-size: 7px;
  }
}

@media screen and (max-width: 320px) {
  .header_content {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 70px;
    flex-wrap: wrap;
  }

  .header_logo {
    width: 65% !important;
    align-items: flex-start;

    img {
      object-fit: contain;
    }
  }
  .burger {
    svg {
      font-size: 32px !important;
      vertical-align: inherit !important;
    }
  }
  .admin_side_bar_mobile .link_mobile span {
    font-size: 7px;
  }
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option-selected,
.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-inner
.side-navigation-panel-select-inner-wrap
.side-navigation-panel-select-inner-option-selected {
  background-color: rgba(154, 174, 179, 0.29);
  border-color: #7b959b;
  padding: 0.75rem 0;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option {
  padding: 0.75rem 0;
  display: flex;
  cursor: pointer;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-inner
.side-navigation-panel-select-inner-wrap
.side-navigation-panel-select-inner-option {
  padding: 0.75rem 1rem;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-inner
.side-navigation-panel-select-inner-wrap
.side-navigation-panel-select-inner-option:hover,
.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option:hover {
  background-color: rgba(154, 174, 179, 0.29);
  border-color: #7b959b;
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-inner
.side-navigation-panel-select-inner-wrap
.side-navigation-panel-select-inner-option
.side-navigation-panel-select-inner-option-wrap
.side-navigation-panel-select-inner-option-text,
.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-wrap
.side-navigation-panel-select-option
.side-navigation-panel-select-option-wrap
.side-navigation-panel-select-option-text {
  font-size: 17px;
  font-weight: bold;
}
