@import '../../assets/styles/variables.scss';

h3 {
  font-weight: bold;
  font-size: 1.4rem;
  margin: 10px 0;
}
h6 {
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
strong {
  margin: 0 5px 0 0;
}
p {
  font-size: 14px;
  line-height: 21px;
}

.card-container {
  height: 100%;
  box-shadow: 0 4px 20px rgb(75 102 197 / 10%);
  border: 1px solid var(--neutral_main);
  border-radius: 4px;
  color: var(--text_main);
  padding: 12px;
  position: relative;
  max-width: 100%;
  margin: 0 0 15px 0;
  .image_block {
    width: 100%;
    margin: 0 auto;
    svg {
      font-size: 8rem;
    }
    img {
      object-fit: contain;
      width: 50%;
      height: 100%;
    }
  }
}
.edit-container {
  box-shadow: 0 4px 20px rgb(75 102 197 / 10%);
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  padding: 13px;
}

.card-container .round {
  border: 1px solid var(--primary_dark);
  border-radius: 50%;
  padding: 7px;
}
button.primary {
  background-color: var(--primary_main);
  border-radius: 3px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  padding: 10px 30px;
}
